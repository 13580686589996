.icons-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.icons-container a {
    padding: 20px;
}

.icons-container a svg {
    width: 120px;
    fill: #FAA307;
}

.icons-container a:hover svg {
    fill: #FFBA08;
}